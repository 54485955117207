@import "node_modules/chartist/dist/scss/chartist.scss";
@import "_variables.scss";

@each $colorname, $color in $account-colors {
    .#{$colorname}-slice {
        fill: $color;
    }

    .#{$colorname}-slice.square {
        background: $color;
        width: 13px;
        height: 13px;
    }
}

.ct-label {
    font-family: $font-family-sans-serif;
    fill: #FFFFFF;
    font-size: 16px;

    @media only screen and (max-width: $widthPhoneLandscape) {
        font-size: 13px;
    }
}

.ct-slice {
    cursor: pointer;
}

.chart {
    margin: 15px;
}

.ct-series, .ct-series > path, .ct-label {
    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
    }
}

.ct-chart-donut {
    filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.3));
}
